<template>
  <v-data-table
    :headers="headers"
    :items="paymentSettlementDriverKasbons"
    sort-by="no"
    dense
    @click:row="onClickRow"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="success"
              v-on="on"
              dark
              rounded
              :to="{ name: 'Create Payment Settlement Driver' }"
            >
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Create</span>
        </v-tooltip>
      </v-toolbar>
    </template>
    <template v-slot:item.no="{ item }">
      {{ paymentSettlementDriverKasbons.map(x => x).indexOf(item) + 1 }}
    </template>
    <template v-slot:item.payDate="{ item }">
      {{ formatDate(item.payDate) }}
    </template>
    <template v-slot:item.bankCharge="{ item }">
      {{ formatPrice(item.bankCharge) }}
    </template>
    <template v-slot:item.totalAmount="{ item }">
      {{ formatPrice(item.totalAmount) }}
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "payment-settlement-driver-kasbon",
  data: () => ({
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Date",
        value: "payDate",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Voucher Number",
        value: "voucherNumber",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Method",
        value: "paymentMethod",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 6,
        text: "Bank Charge",
        value: "bankCharge",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 7,
        text: "Total",
        value: "totalAmount",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
  }),

  computed: {
    ...mapState("paymentSettlementDriver", ["paymentSettlementDriverKasbons"]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("paymentSettlementDriver/getAll")
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onClickRow(item) {
      this.$router.push({ name: "Detail Payment Settlement Driver", params: { id: item.id } });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date) {
      return moment(date).format("DD-MM-yyyy");
    },
  },
};
</script>

<style></style>
